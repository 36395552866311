import Vue from 'vue'
import Vuex from 'vuex'
import countries from './modules/countries'
import auth from './modules/auth'
import samo from './modules/samo'
import region from './modules/region'
import hotel from './modules/hotel'
import report from './modules/report'
import ow from './modules/ow'
import airport from './modules/airportlink'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    countries,
    auth,
    samo,
    region,
    hotel,
    report,
    ow,
    airport
  }
})
