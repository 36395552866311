import axios from 'axios'

export default {
    actions: {
        async sa_AirportSearch({ commit },filter) {
            return new Promise((resolve, reject) => {

                commit('sm_waitAirportSearch', true)

                axios.post(window.url_api + 'api/AirportLink/AirportSearch',
                filter
                , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
						
                        commit('sm_AirportSearch', response.data.result)
						resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitAirportSearch', false)
						reject(new Error("Ошибка"));
                    })
                    .finally(() => {
                
                        commit('sm_waitAirportSearch', false)
                    })
            })
        },

        async sa_RegionDetails({ commit },filter) {
            return new Promise((resolve, reject) => {

                commit('sm_waitRegionDetails', true)

                axios.post(window.url_api + 'api/Region/RegionDetails',
                filter
                , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
						
                        commit('sm_RegionDetails', response.data.result)
						resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitRegionDetails', false)
						reject(new Error("Ошибка"));
                    })
                    .finally(() => {
                
                        commit('sm_waitRegionDetails', false)
                    })
            })
        },

        async sa_RegionAirports({ commit },filter) {
            return new Promise((resolve, reject) => {

                commit('sm_waitRegionAirports', true)

                axios.post(window.url_api + 'api/Region/RegionAirports',
                filter
                , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
						
                        commit('sm_RegionAirports', response.data.result)
						resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitRegionAirports', false)
						reject(new Error("Ошибка"));
                    })
                    .finally(() => {
                
                        commit('sm_waitRegionAirports', false)
                    })
            })
        },

        async sa_RegionLinkAirport({ commit },filter) {
            return new Promise((resolve, reject) => {

                commit('sm_waitRegionLinkAirport', true)

                axios.post(window.url_api + 'api/Region/RegionLinkAirport',
                filter
                , {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => {
                        console.log(response.data.result)
						
                        commit('sm_RegionLinkAirport', response.data.result)
						resolve(response.data.result)
                    })
                    .catch(error => {
                        console.log(error.response)
                        commit('sm_waitRegionLinkAirport', false)
						reject(new Error("Ошибка"));
                    })
                    .finally(() => {
                
                        commit('sm_waitRegionLinkAirport', false)
                    })
            })
        },


    },
    mutations: {



        sm_AirportSearch(state, resultAirportSearch) {
            state.resultAirportSearch = resultAirportSearch
        },

        sm_waitAirportSearch(state, waitAirportSearch) {
            state.waitAirportSearch = waitAirportSearch
        },

        sm_RegionDetails(state, resultRegionDetails) {
            state.resultRegionDetails = resultRegionDetails
        },

        sm_waitRegionDetails(state, waitRegionDetails) {
            state.waitRegionDetails = waitRegionDetails
        },

        sm_RegionAirports(state, resultRegionAirports) {
            state.resultRegionAirports = resultRegionAirports
        },

        sm_waitRegionAirports(state, waitRegionAirports) {
            state.waitRegionAirports = waitRegionAirports
        },

        sm_RegionLinkAirport(state, resultRegionLinkAirport) {
            state.resultRegionLinkAirport = resultRegionLinkAirport
        },

        sm_waitRegionLinkAirport(state, waitRegionLinkAirport) {
            state.waitRegionLinkAirport = waitRegionLinkAirport
        },




    },
    state: {
        resultAirportSearch: [],
        waitAirportSearch: false,

        
        resultRegionDetails: [],
        waitRegionDetails: false,

        resultRegionAirports: [],
        waitRegionAirports: false,

        resultRegionLinkAirport: [],
        waitRegionLinkAirport: false,
    },
    getters: {


        getresultAirportSearch: state => state.resultAirportSearch,
        getwaitAirportSearch: state => state.waitAirportSearch,

        getresultRegionDetails: state => state.resultRegionDetails,
        getwaitRegionDetails: state => state.waitRegionDetails,

        getresultRegionAirports: state => state.resultRegionAirports,
        getwaitRegionAirports: state => state.waitRegionAirports,

        getresultRegionLinkAirport: state => state.resultRegionLinkAirport,
        getwaitRegionLinkAirport: state => state.waitRegionLinkAirport,
    }
}
