<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand href="#">CMS </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-navbar-nav>
            <b-nav-item href="#"  v-if="checkAction(1)" :to="'/countries'">Страны</b-nav-item>
            <b-nav-item href="#"  v-if="checkAction(2)"  :to="'/regions'">Регионы</b-nav-item>
            <b-nav-item href="#"  v-if="checkAction(3)"  :to="'/hotels'">Отели</b-nav-item>
            <b-nav-item href="#"  v-if="checkAction(4)"  :to="'/hotelsauto'">Отели авто</b-nav-item>
            <b-nav-item href="#"  v-if="checkAction(4)"  :to="'/hotelswar'">Отели предупреждения</b-nav-item>
            <b-nav-item href="#"  v-if="checkAction(5)"  :to="'/mappreport'">Отчет маппинга</b-nav-item>
            <b-nav-item href="#"  v-if="checkAction(4)"  :to="'/airportlink'">Регион-Аэропорт</b-nav-item>
            <b-nav-item href="#"  v-if="checkAction(6)"  :to="'/SamoPriceAnalysis'"
              >Анализ цен</b-nav-item
            >
          </b-navbar-nav>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
        <!--   <b-nav-form>
            <b-form-input
              size="sm"
              class="mr-sm-2"
              placeholder="Search"
            ></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit"
              >Search</b-button
            >
          </b-nav-form>
        -->
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <b><em>{{ (userinfo != null)?userinfo.login:""}}</em></b>
            </template>
            <b-dropdown-item :to="'/singin'">Вход</b-dropdown-item>
            <b-dropdown-item :to="'/Profile'">Профиль</b-dropdown-item>
           
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div class="border" style="overflow-x: auto">
      <router-view />
    </div>
    <div text-right> {{(userinfo != null)? userinfo.envir:"..." }}</div>
</div>
</template>

<script>
import {
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BCollapse,
  BNavbarNav,
  BNavItem,
  //BNavForm,
  //BFormInput,
 // BButton,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  name: "NavbarExample",
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BNavItem,
    //BNavForm,
    //BFormInput,
    //BButton,
    BNavItemDropdown,
    BDropdownItem,
  },
  computed:{
    userinfo() {
      return this.$store.getters.getuserinfo;
    },

    actionIds(){
      return this.$store.getters.getuseractionids;
    }

  },
  methods: {
    checkAction: function (actionId) {
     return this.actionIds.includes(actionId);
    }
  },

  mounted() {
  //  this.$store.dispatch("sa_syncfilter");
  this.$store.dispatch("sa_syncfilter").then(() => {
      console.log("app vue sa_syncfilter ")
    });
  },
};
</script>